<template>
    <workspace-wrapper>
        <template #header>
            <default-slot-header :bet="stateData.currentSpinResult.bet"
                                 :lines="stateData.currentSpinResult.linesCount"
                                 :stake="stateData.currentSpinResult.stake"
            />
            <free-spins-slot-header v-if="stateData.currentSpinResult.isFreeSpin"
                                    v-bind="stateData.currentSpinResult.bonusFreeSpins"
            />
        </template>

        <template #workspace>
            <lucky-pharaoh-free-workspace v-if="stateData.currentSpinResult.isFreeSpin"

                                          :layouts="layouts"
                                          :symbols-path="symbolsPath"
            />
            <default-slot-workspace v-else

                                    :layout="stateData.currentSpinResult.layout"
                                    :symbols-path="symbolsPath"
            />
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../Common/WorkspaceWrapper";
import DefaultSlotHeader from "./Common/Header/DefaultSlotHeader";
import FreeSpinsSlotHeader from "./Common/Header/FreeSpinsSlotHeader";
import DefaultSlotWorkspace from "./Common/DefaultSlotWorkspace";
import LuckyPharaohFreeWorkspace from "@/components/Spins/Games/Slots/Common/LuckyPharaohFreeWorkspace";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        balanceBefore: null,
                        bet: null,
                        linesCount: null,
                        stake: null,
                        layout: null,
                        isFreeSpin: null,
                        bonusFreeSpins: {
                            totalWin: null,
                            totalFreeSpins: null,
                            currentFreeSpin: null,
                            freeSpinsAwardedCount: null
                        },
                        subSpinResults: [{
                            layout: null
                        }]
                    }
                }
            }
        }
    },
    data() {
        return {
            symbolsPath: 'luckypharaoh'
        }
    },
    computed: {
        layouts() {
            if (!this.stateData.currentSpinResult.isFreeSpin)
                return [];

            return this.stateData.currentSpinResult.subSpinResults.map(subResult => subResult.layout);
        }
    },
    components: {
        LuckyPharaohFreeWorkspace,
        WorkspaceWrapper,
        DefaultSlotHeader,
        DefaultSlotWorkspace,
        FreeSpinsSlotHeader
    }
}
</script>

<style scoped>

</style>
